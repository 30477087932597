import { UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';

import { CustomValidators } from './custom-validators';
import { shouldNotContainHttpLinkValidator } from './validators/should-not-contain-http-link.validator';

// const reg = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;

const ShortField: stringValFnArr = [
  '',
  [
    Validators.required,
    CustomValidators.notOnlyWhitespace,
    Validators.minLength(1),
    Validators.maxLength(60),
  ],
];

const ShortFieldNoHttpLinks: stringValFnArr = [
  '',
  [...ShortField[1], shouldNotContainHttpLinkValidator],
];

const ShortFieldOpt: stringValFnArr = ['', [Validators.maxLength(60)]];

const LongField: stringValFnArr = ['', [Validators.maxLength(220)]];

const LongRequiredField: stringValFnArr = [
  '',
  [Validators.required, Validators.maxLength(220)],
];

const requiredMaxLengthField = (length: number): [string, any?, any?] => [
  '',
  [
    Validators.required,
    CustomValidators.notOnlyWhitespace,
    Validators.maxLength(length),
  ],
];

const BooleanField: booleanValFnArr = [false, [Validators.required]];

const DescField: stringValFnArr = ['', [Validators.maxLength(500)]];

const ContentField: stringValFnArr = ['', [Validators.maxLength(3000)]];

const MaxField: stringValFnArr = ['', [Validators.maxLength(5000)]];

const EmailField: stringValFnArr = [
  '',
  [Validators.required, CustomValidators.emailValidator],
];
const PasswordField: stringValFnArr = [
  '',
  [Validators.required, Validators.minLength(6), Validators.maxLength(60)],
];

const TitleField: stringValFnArr = [
  '',
  [
    Validators.required,
    CustomValidators.notOnlyWhitespace,
    Validators.minLength(1),
    Validators.maxLength(100),
  ],
];

const TitleFieldNoLinks: stringValFnArr = [
  '',
  [...TitleField[1], shouldNotContainHttpLinkValidator],
];

const NameField: stringValFnArr = [
  '',
  [
    CustomValidators.notOnlyWhitespace,
    Validators.minLength(1),
    Validators.maxLength(60),
    shouldNotContainHttpLinkValidator,
  ],
];

const PhoneField: stringValFnArr = [
  '',
  [
    Validators.maxLength(20),
    Validators.minLength(7),
    CustomValidators.onlyPhoneNumberCharacters,
  ],
];

const FormControlsInternal = {
  isValid: (form: UntypedFormGroup) =>
    form.invalid || form.pending || !form.dirty,

  requiredMaxLengthField,
  BooleanField,
  DescField,
  LongField,
  LongRequiredField,
  ShortField,
  ShortFieldOpt,
  MaxField,
  ContentField,
  TitleField,
  TitleFieldNoLinks,
  PwaShortname: requiredMaxLengthField(12), // as xdescribed in manifest documentation
  PwaName: requiredMaxLengthField(45), // as xdescribed in manifest documentation
  ShortFieldNoHttpLinks,
  NameField,
  EmailField,
  PasswordField,
  PhoneField,
};

type stringValFnArr = [string, ValidatorFn[]];
type booleanValFnArr = [boolean, ValidatorFn[]];

export const FormControls = Object.freeze(FormControlsInternal);
