import { AbstractControl } from '@angular/forms';
import { checkIfTemplateIncludeLinks } from '@memberspot/shared/util/helper';

export const shouldNotContainHttpLinkValidator = (
  control: AbstractControl,
): { hasLinkInText: string } | null => {
  if (!control || !control.value) {
    return null;
  }

  if (!checkIfTemplateIncludeLinks(control.value || '')) {
    return null;
  } else {
    return { hasLinkInText: control.value };
  }
};
